@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Arial, sans-serif;
  background-color: #FFFFFF;
  padding: 20px;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
}

input[type="number"] {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

button {
  background-color: #3B82F6;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  opacity: 0.8;
}

.border {
  border-color: #e5e7eb;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}
